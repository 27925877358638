import { ref } from 'vue'
import getAPiNotificationsList from '../../../../../services/getNotificationsList'

const notificationsList = ref([])
const getNotificationsList = async () => {
  notificationsList.value = await getAPiNotificationsList()
}

const useDataTable = () => {
  return {
    notificationsList,
    getNotificationsList
  }
}
export {
  useDataTable
}
